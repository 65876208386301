.hint {
    position: relative;
    display: inline-block;
    font-size: 18px;
    .hint-text {
        visibility: hidden;
        width: 240px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 10px;
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 50%;
        margin-left: -60px;
        font-size: 14px;
        font-weight: normal;
    }
    &:hover .hint-text {
        visibility: visible;
    }
}