.tree {
    margin-left: -15px;

    ul {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;

        //&:before {
        //    content: "";
        //    display: block;
        //    width: 0;
        //    position: absolute;
        //    top: 0;
        //    bottom: 0;
        //    left: 0;
        //    border-left: 1px solid #CCCCCC;
        //}

    }

    li {
        margin: 0;
        //padding: 5px 0 5px 15px;
        line-height: 18px;
        color: $main-text;
        font-weight: 400;
        position: relative;
        //border-bottom: 1px solid grey;

        a {
            text-decoration: none;
            color: white;
        }

        &:first-of-type {
            margin-top: 10px;
        }

        &:last-of-type {
            border: none;
        }
    }

    &>li {
        border: none !important;
    }
}