.profile-navigation {
    li {
        display: inline-block;
        float: left;
        border-bottom: 1px solid $hr-color;
        padding-bottom: 1px;
        a {
            padding: 10px 12px;
            text-transform: uppercase;
            color: #333333;
            &:hover, &:focus, &:active, &:visited{
                background: none;
            }
        }

        &.active {
            border-bottom: 2px solid black;
            padding-bottom: 0;
            a {
                color: $main-text;
                font-weight: 600;
            }
        }
    }
}
