//backgrounds
$site-background: white;
//$site-background: #303a44;
$content-background: #ffffff;
$homepage-background: #ffffff;
//$dark-section-background: yellow;
$dark-section-background: #303a44;

//links
$link-color: #56A6E9;
$link-black-color: #333333;
$link-read-more: #e46327;

//text
$main-text: #333333;
$gray-text: #a6a6a6;

//bullets
$bullets-color: #4381b5;

//hr's
$hr-color: grey;

$outer-color: #333333;


//icons
$icon-white: white;
$icon-dark: #333333;

//statuses
$success-color: #4b7237;

//shadows
$shadow: 1px 1px 15px rgba(0, 0, 0, 0.25);
$shadow-hover: 1px 1px 15px rgba(0, 0, 0, 0.35);