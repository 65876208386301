.link-black {
    color: $link-black-color;
    text-decoration: none;
    &:hover, &:active, &:focus {
        color: $link-black-color;
        text-decoration: none;
    }
}

.learn-more {
    text-transform: uppercase;
    color: $link-read-more !important;
    font-size: 16px;
    padding-top: 20px;
    display: inline-block;
    text-decoration: none;
    &:hover, &:active, &:focus {
        color: $link-read-more !important;
        text-decoration: none;
    }
}
