.navigation2 {
    display: none;
    width: 100%;
    border-radius: 0;
    //background: $site-background url("/images/header-star.png") top left no-repeat;
    background: $dark-section-background;
    z-index: 100;

    @include breakpoint(1200) {
        display: block;
        height: 100px;
    }

    @include breakpoint(1440) {
        // width: 1440px;
    }

    .navbar-brand {
        max-width: 300px;
        height: 100%;

        img {
            max-height: 68px;
        }
        @include breakpoint(1200) {
            position: absolute;
            top: 0
        }
    }

    .site-navigation {
        margin-left: 25px;
        float: right;

        li {

            &:last-of-type {
                a {
                    padding-right: 0;
                }
            }

            a {
                color: $content-background;
                font-size: 18px; 
                font-weight: 500;
                text-transform: uppercase;
                padding-top: 8px !important;
                padding-bottom: 7px !important;

                &:active {
                    color: #01FFDC;
                    background: none;
                }

                &:hover, &:focus, &.active {
                    background: none;
                    color: #01FFDC;
                }
            }

        }
    }
}

#affix2 {
    position: fixed;
    top: 0;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, .5)
}


