.nav-footer {
    background: $dark-section-background;
    padding-top: 45px;
    padding-bottom: 45px;
    @include breakpoint(768) {
        //margin-top: 30px;
        padding: 45px;
    }
    @include breakpoint(1200) {
        padding-right: 30px;
        padding-left: 30px;
    }
    p {
        color: $main-text;
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 15px;
    }
    ul {
        padding: 0;
        li{
            list-style-type: none;
            padding:5px 0;
            a {
                color: $main-text;
                font-size: 18px;
                font-weight: 400;
                address {
                    margin: 0;
                }
            }
        }
    }

    .footer-icon-svg {
        margin:0 10px 0 0;
        float: left;
        fill: $main-text;

        &.pin {
            margin-top: 7px;
        }
    }

    .follow-us {
        margin: 0;
        font-weight: 300;
    }

    .footer-column {
        margin-bottom: 35px;
        @include breakpoint(992) {
            margin-bottom: 0;
            padding: 0 30px;
        }
        @include breakpoint(1200) {
            padding: 0 25px;
        }
        img{
            max-height: 80px;
            max-width: 140px;
            @include breakpoint(768) {
                margin: 0 auto;
            }
            
        }
    }

    .follow-us {
        display: inline-block;
        float: right;
    }
}

.footer-social-wrapper {
    background: #ddd;
    @include breakpoint(768) {
        padding: 0 45px;
    }
    @include breakpoint(1200) {
        padding: 0 30px;
    }
    .footer-message  {
        color: $main-text;
        font-size: 18px;
        margin: 15px 0;
        display: inline-block;
    }
}