.navbar {
    margin-bottom: 0 !important;
    border: none !important;
}

.link-clear {
    color: inherit;
    &:focus, &:visited, &:hover, &:active {
        color: inherit;
        text-decoration: none;
    }
}