.auth-feed {
    &.page-bg.container-fluid {
        background: $site-background;
    }
}

.page-bg.auth-page {
    background: white;
    @include breakpoint(768) {
        padding: 60px 0;
    }
}