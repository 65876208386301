.main-section {
    .main-content {
        background: $content-background;
        padding: 15px;

        .contact-col {
            margin-bottom: 5px;
            .social-icon-svg {
                margin-right: 5px;
            }
        }
    }
}