.slider-content{
    margin: 0 auto;
    position: relative;
    .home-slider{
        position: relative;
        .slider-img{
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    &.content-wrapper{
        padding:0 !important;
        @include breakpoint(1200) {
            padding:0 15px !important;
        }
    }
}

.slider-section {
    z-index: 100;
    margin: 0 auto;
    overflow:hidden;
    
    .content {
        margin: 0 auto;
        position: relative;
        .header-content {
            z-index: 4;
            position: absolute;
            display: block;
            bottom: 60px;
            left: 25px;
        }
    }
    .image-blur{
        background-size: cover;
    }
}

.image-blur {
    width: 100%;
    filter: blur(50px) brightness(80%);
    position: absolute;
    top: 0;
    background-size: cover;
    overflow: hidden;
    opacity:0;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
    margin: 0 10px;
}

.members-pagination {
    position: static;
}

.projects-pagination {
    position: static;
}

.internships-pagination {
    position: static;
}
