.button-standard {
    display: inline-block;
    padding: 8px 15px;
    color: white;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    &:hover, &:active, &:visited {
        color: white;
        text-decoration: none;
    }

    &:hover {
        cursor: pointer;
    }

    @extend .button-standard-gradient;
}


.button-search {
    padding: 8px 12px 3px;
    height: 34px;
}

.button-white-outlined {
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    border: 2px solid white;
    padding: 8px 15px;
    display: inline-block;

    &:hover, &:active, &:visited {
        color: white;
        text-decoration: none;
    }

    &:hover {
        cursor: pointer;
    }
}