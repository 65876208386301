.sorting-module {
    width: 100%;
    background: gainsboro;
    padding: 10px 15px;
    border-radius: 8px;
    margin: 0px 0 20px;

    .filter-label {
        padding-left: 10px;
    }
}