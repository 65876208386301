.boxes {
    overflow: hidden;
    //margin-left: -30px;
    //margin-right: -30px;
}

.feed-separator {
    background: white;
    height: 3px;
    width: 60px;
}

html, body {
    font-family: Roboto;
}