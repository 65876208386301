.login-form {
    .btn-link {
        padding-left: 0;
    }
}

.auth-feed {
    &.page-bg.container-fluid {
        background: $site-background;
    }
}
