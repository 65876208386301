body {
    background: $site-background;
}

a {
    color: $link-color;
}

//site content-wrapper
.content-wrapper {
    //background: #ff8d8d;
    max-width: 1440px;
    margin: 0 auto;
}

.esspo-wrapper {
    max-width: 100%;
    margin: 0 auto;

    padding-top: 50px;
    @include breakpoint(1200) {
        padding-top: 100px;
    }
    .home-slider-flex-container{
        display: flex;
        flex-direction: column;
        .home-slider {
            min-height:250px;

            @include breakpoint(1200) {
                min-height:450px;
            }
        }
        
        .slider-area{
            flex-grow: 1;
        }
    }
    .nav-desktop{
        display: none;
        @include breakpoint(1200) {
            display: block;
        }
    }
    .nav-mobile{
        display: block;
        @include breakpoint(1200) {
            display:none;
        }
    }
        }

.page-bg {
    background: $content-background;
    
    .full-width{
        background: #f6f8fb;
        .breadcrumbs{
            .breadcrumb{
                background: #f6f8fb;
            }
        }
    }

    &.container-fluid {
        padding-top: 25px;
        background: $content-background;

        &.news-container {
            background: $site-background;
        }

        &.feed-container {
            background: $site-background;
        }
    }
}

.equal-padding{
    padding:45px 0;
    margin: 0 auto;
}

.underlined-heading {
    hr {
        margin: -1px 0 0 0;
        border-color: $hr-color;
    }

     h3, h4, h5 {
        border-bottom: 2px solid black;
        display: inline-block;
        margin-bottom: 0;
        padding: 0 0 10px 0;
        font-size: 25px;
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 14px;
        font-weight: 300;
        padding: 0 0 5px 0;
    }

}

//Custom container breakpoint
.container {
    @include breakpoint(1600) {
        width: 1440px !important;
    }
}

//change bootstrap collapse breakpoint
@media (max-width: 1200px) {
    .navbar-header {
        float: none;
        background: #303a44;
    }

    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}

.label-default {
    background-color: #eeeeee;
}

.nav-pills {
    &.slim {
        li {
            a {
                padding: 2px 15px;
            }

            span {
                padding: 2px 15px;
            }
        }
    }
}

.taglist {
    display: inline;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        display: inline;
        &:after {
            content: ", ";
            margin-left: -.2em;
        }

        &:last-child {
            &:after {
                content: "";
                margin-left: -.2em;
            }
        }
    }

    &.clear {
        li {
            display: inline;
            &:after {
                margin-left: 0;
            }

            &:last-child {
                &:after {
                    margin-left: 0;
                }
            }
        }
    }
}

.contacts-table {
    width: 100%;

    th:last-child {
        width: 175px;
        text-align: center;
    }

    tr {
        td:last-child {
            width: 175px;
            text-align: right;
        }
    }
}

.esspo-articles{
    .article-v1 {
    height: 410px;
    overflow: hidden;
    background: transparent;
    &.longer{
        height:440px !important;
        .heading-section{
            .article-text{
                height: 128px;
                margin-bottom: 20px;
            }
        } 
    }
    .inner-slider-container{
        border: 1px solid #d1d1d1;
        box-shadow: 3px 3px 5px #d1d1d1;
        padding:10px;
        height:95%;
        width:98%;
        background:#FFF;
        &.member{
            /*padding-top:30px;*/
            text-align: center;
            .article-date{
                text-align: left;
            }
        }
    }

    .fa {
        font-size: 160px;
    }
    .article-img{
        height: 150px !important;
        &.feeds{
                height: 150px;
                /*border: 10px solid rgba(189, 195, 199, 0.6);*/
                margin: 0 auto;
                div{
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    width:100%;
                    height:100%;
                }

                img{
                    width: 100%;
                    height: 100%;
                    flex: 1 1 auto;
                }
                &.no-img {
                    font-size: 106px;
                    padding-top:4px;
                    &.internee-img{
                        padding-top:12px;
                    }
                }
            &.member-img{
                border-radius: 50%;
                height: 150px;
                width: 150px;
                border: 10px solid rgba(189, 195, 199, 0.6);
                margin: 0 auto;
                div{
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    width:100%;
                    height:100%;
                    border-radius: 50%;
                }

                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%; 
                    flex: 1 1 auto;
                }
                &.no-img {
                    font-size: 106px;
                    padding-top:4px;
                }
            }

        }
    }

    .heading-section {
        padding: 0px;
        &.member{
            text-align: center;
            .article-date{
                text-align: left;
            }
        }
        .article-heading {
            height: 60px;
            margin-top: 5px !important;
            margin-bottom: 20px;
            h3, h4{
                margin: 0 !important;
                padding-top:5px;
            }
            h3 {
                font-size: 18px;
            }
            hr{
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }
            }
        .article-text {
            font-size:14px;
            height: 80px;
            overflow: hidden;
            margin-bottom: 20px;
            p{
                margin: 0 !important;
                padding-top:2px;
                &.special1{
                    height: 20px;
                    overflow: hidden;
                }
                &.special2{
                    height:60px;
                    overflow: hidden;
                }
            }
        }

        .article-date{
            color: #333333;
            font-weight: 100;
            font-size: 12px;
        }
    }
    hr.no-margin{
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    
}
}

.label-info {
    background-color: #01FFDC !important;
    color:#333333 !important;
}



