.breadcrumbs {
    padding: 10px 0;
    font-weight: 300;
    color: #333333;

    .breadcrumb {
        padding: 8px 0;
        margin-bottom: 0!important;

        & > li + li:before {
            color: #333333;
        }

        & > li a {
            color: #333333;
        }

        & > .active {
            font-weight: 600;
            color: #333333;

            &::before {
                font-weight: normal;
            }
        }
    }

    .breadcrumb-current {
        font-weight: 600;
        color: #333333;
    }
}