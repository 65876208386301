.form-horizontal {
    .form-control {
        -webkit-box-shadow: 0 0 0 30px white inset;
        -webkit-text-fill-color: #333333 !important;
        border-radius: 0;
    }

    .control-label {
        text-align: left;
    }
}
