.profile {
    .profile-add {
        margin:20px 0;
    }

    h3.user-name {
        color: $main-text;
        font-size: 20px;
        //font-style: italic;
        margin-top: 10px;
    }

    @include breakpoint(992) {
        .profile-wrapper {
            padding: 30px 0;
            position: relative;
            .vertical-separator {
                position: absolute;
                width: 1px;
                background: $hr-color;
                height: 100%;
                right: 25%;
            }
        }
    }

    .profile-label {
        h1, h2, h3, h4, h5 {
            border-bottom: 2px solid black;
            display: inline-block;
            margin-bottom: 0;
            padding-bottom: 5px;
            color: $link-color;
            text-transform: uppercase;
        }

        hr {
            margin: -1px 0 0 0;
            border-top: 1px solid $hr-color;
        }
    }
}

.profile-actions-block {
    i {
        margin-right: 8px;
    }
}