.news-section {
    background: white;
    padding-bottom: 30px;

    h3 {
        font-size: 20px;
    }

    .news-label {
        hr {
            margin: -1px 0 0 0;
            border-color: $main-text;
        }

        h2 {
            color: $main-text;
            margin-bottom: 0;
            padding: 45px auto;
            font-size: 26px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: left;
        }
    }

    .heading-section {
        hr {
            margin: 0 auto;
        }
    }

    .esspo-articles {

        .article-v1 {
                margin-bottom: 15px;
                height: 410px;
                .inner-slider-container{
                    border: 1px solid #d1d1d1;
                    box-shadow: 3px 3px 5px #d1d1d1;
                    width:98%; 
                    padding:10px;
                    height:95%;
                }
            a {
                color: $main-text;

                &:active, &:hover, &:active, &:visited, &:focus {
                    color: $main-text;
                }
            }

            .article-text {
                color: $main-text;
                font-size: 14px;
                height:80px;
                overflow:hidden;
                text-overflow: ellipsis;
                p{
                    margin: 0 !important;
                }
            }

            .article-date {
                color: $main-text;
                font-weight: 100;
                font-size: 12px;
                height:50px;
            }
        }

        .news-heading {
            h3 {
                text-transform: uppercase;
                font-size: 20px;
                margin-bottom: 0;
            }

            .news-date {
                font-weight: 100;
            }
        }
    }
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
        margin: 0 10px;
    }

    .swiper-pagination {
        position: static;
    }
}