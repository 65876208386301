@import "partials/show";

.companies {
    //padding-left: 20px;

    .company-tile {
        height: 240px;
        margin-bottom: 30px;

        .company-title {
            font-size: 16px;
            color: #333333;
            height: 25px;
            padding: 0px 10px;
        }

        .activity-subject {
            height: 60px;
            overflow: hidden;
            padding: 0 10px;
            color: $outer-color;
        }

        .company-logo-container {
            height: 150px;
            white-space: nowrap;
            text-align: center;
            margin: 1em 0;

            background: white;
            box-shadow: $shadow;

            .helper {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }

            .company-logo {
                display: inline-block !important;
                margin: 0 auto;
                vertical-align: middle;
            }
        }

        &:hover {
            .company-logo-container {
                box-shadow: $shadow-hover;
            }
        }
    }
}
