.esspo-articles {
    &.members{
        height: 300px;

        .article-v1 {
            height: 300px;
            margin-top: 8%;

            .far, .fa {
                margin: 0px auto;
                font-size: 160px;
                color: #303a44;

                &:hover {
                    color: #01FFDC;
                }
            }
        }
    }
}
