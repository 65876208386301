.last-hr {
    border-top: 1px solid $hr-color;
}

.separator-with-title {
    h1 {
        text-align: center;
        color: white;
        margin: 0;
        padding: 30px 0;
        font-size: 75px;
    }

    &.default-color {
        @extend .default-blue-gradient;
    }
}


.page-separator-big {
    @extend .default-blue-gradient;
    height: 20px;
}

.page-separator {
    height: 10px;
    @extend .default-blue-gradient;
}

.horizontal-line {
    margin: 10px 0;
    .element-one {
        height: 3px;
        width: 80px;
        background: black;
    }

    .element-two {
        border-top:1px solid $hr-color;
    }
}

.pagination-wrapper {
    .pagination {
        .active {
            span {
                background-color: $link-color;
            }
        }
    }
}