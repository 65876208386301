//SVG icons
.icon {
    /* Lets the icon inherit the text color. */
    fill: currentColor;

    /* Paths and strokes that overflow the viewBox can
       show in IE. If you use normalize.css, it already
       sets this. */
    overflow: hidden;

    /* Rotate icons 90deg, 180deg and -90deg */
    &.left {
        -ms-transform: rotate(90deg); /* IE 9 */
        -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
        transform: rotate(90deg);
    }

    &.right {
        -ms-transform: rotate(-90deg); /* IE 9 */
        -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
        transform: rotate(-90deg);
    }

    &.upside-down {
        -ms-transform: rotate(180deg); /* IE 9 */
        -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
        transform: rotate(180deg);
    }
}


.social-icon-svg {
    fill: $icon-white;
    height: 20px;
    width: 20px;
    &.black {
        fill: black;
    }
}

.search-icon-svg {
    fill: $main-text;
    height: 15px;
    width: 15px;
}

.footer-icon-svg {
    fill: $icon-white;
    height: 25px;
    width: 25px;
}

.esspo-logo {
    height: 100px;
    width: 160px;
}
