.blocks-section {
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    background-color:#FFFFFF;
    .opacity {
        opacity:0;
    }
    .blocks {
        
        color: white;
        display: flex; /* or inline-flex */
        align-items: stretch;
        flex-wrap:wrap;
        padding-bottom:45px;
        
        @include breakpoint(1200) {
            padding-left:15px;
            padding-right:15px;
        }
        .img-block, .text-block {
            
            display: inline-flex;
            justify-content: center;
            flex-direction: column;
            text-align: left;
            flex-grow: 1;
            width: 100%;
            padding: 20px 15px;
            @include breakpoint(768) {
                width: calc(50%);
                height: 520px;
                padding: 35px;
            }
            &.opacity{
                opacity:0;
            }
            .title {
                margin: 0;
            }

            .text {
                line-height: 1.2;
                font-size: 17px;
                margin-top: 20px;
                @include breakpoint(992) {
                    line-height: 1.4;
                    font-size: 20px;
                }
            }

            .cta {
                margin-top: 20px;
                a {

                }
            }
        }

        .img-block {
            display: none;
                background-repeat: no-repeat;
            @include breakpoint(768) {
                display: inline-block;
            }
        }
    }
}