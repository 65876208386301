.eds-tile {
    height: 160px;
    margin-bottom: 20px;
    position: relative;
    background: white;
    box-shadow: $shadow;
    &:hover {
        box-shadow: $shadow-hover;
    }
    //a {
    //    color: black;
    //    &:hover{
    //        text-decoration: none;
    //    }
    //}

    .eds-photo {
        float: left;
        margin: 18px 10px 32px;
        width: 100px;
        height: 100px;
    }

    .tile-p1 {
        position: relative;
        max-height: 80px;
        overflow: hidden;
        margin-bottom:10px;

        .eds-name {
            font-size: 14px;
            padding: 15px 0 0;
            height: 39px;
            @include breakpoint(544) {
                font-size: 16px;
                padding: 15px;
                height: 39px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 23ch;
            }
        }


        .eds-education-label {
            font-weight: 600;
            font-size: 10px;
            @include breakpoint(544) {
                font-size: 12px;
            }
        }

        .eds-education-text {
            font-size: 12px;
            max-height: 35px;
        }

        .eds-internship-label {
            font-size: 12px;
            //padding-top: 5px;
            font-weight: 600;
        }

        .eds-internship-number {
            font-size: 12px;
        }
    }

    .tile-p2 {
        height: 70px;
        overflow: hidden;
        .activity-subject {
            //padding: 10px 15px 0;
            padding-right: 10px;
            font-size: 12px;
        }
    }

    .dots {
        position: absolute;
        top: 0;
        right: 20px;
        font-size: 25px;
        color: $link-color;
    }
}

.product-tile {
    background: white;
    padding: 15px;
    box-shadow: 0 4px 6px #00000011;
    margin-bottom: 20px;
    .tile-p1 {
        height: 110px;
        img {
            float:left;
            display: inline-block;
            max-height: 110px;
            max-width: 110px;
            margin-right: 10px;
        }

        .product-name {
            padding: 15px;
        }

        .product-desc {
            margin: 0 15px;
        }
    }
}

