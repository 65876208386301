.search-results {
    .heading {
        font-size: 28px;
    }

    .results {
        margin-top: 10px;
    }

    .results-counter {
        color: $gray-text;
        font-size: 14px;
    }
}