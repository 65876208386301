.single-article {
    margin-bottom: 50px;

    .video{
        margin: 20px auto;
        iframe{
            width:100% !important;
        }
    }
    img{
        display: block;
        margin-bottom:30px;
        float:none;
        @include breakpoint(992) {
            display: inline;
            max-width: 40%;
            float: left;
            padding: 0 30px 0 0;
        }
    }
    .article-heading {
        margin: 10px 0 20px !important;
    }

    hr {
        padding: 0;
        margin: 0;
    }

    p {
        font-size: 16px;
        line-height: 26px;
    }

    .news-date {
        margin-top: 10px;
        font-weight: 100;
        color: #333333;
    }

    .article-image {
        margin-top: 20px;
    }

    .article-text {
        margin-bottom: 20px;
        font-size: 16px;
        line-height:26px;
        p{
            margin: 0 !important;
        }
    }
}