.side-menu {
    text-transform: uppercase;
    list-style: none;
    padding-left: 0;
    padding-bottom: 20px;

    li {
        padding: 5px 0 5px 15px;

        &.active-root-level {
            ul {
                li {
                    display: block;
                }
            }

            a {
                &.menu-root-link {
                    color: $main-text !important;
                    font-weight: 600 !important;
                }
            }
        }

        a {
            font-size: 16px;
            color: $main-text;
            text-decoration: none;

            &.active-root-level, &.active-level {
                color: $main-text !important;
                font-weight: 600 !important;
            }
        }

        ul {
            list-style: none;
            padding-left: 0;
            margin-left: 0;
            li {
                display: none;
                a {
                    font-size: 12px !important;
                    color: $outer-color !important;
                    font-weight: normal !important;

                    &.active {
                        color: white !important;
                        font-weight: 600 !important;
                    }
                }
            }

        }

        &.useful {
            //padding-left: 0;
            a {
                font-size: 16px !important;
            }
        }
    }
}

//@media (min-width: 992px) {
//    .public-wrapper {
//        position: relative;
//    }
//
//    .vertical-separator {
//        position: absolute;
//        width: 1px;
//        background: grey;
//        height: 90%;
//        top: 0px;
//        right: 25%;
//    }
//}
