.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.image-container {
    #feature-image {
        height: 130px;
        //width: 130px;
    }

    .company-logo-img {
        display: block;
        margin: 0 auto;
        width: 235px;
        img {
            max-width: 200px;
            max-height: 200px;
        }
    }

    .upload-actions {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    #save-form-hint {
        color: #4b7237;
    }
}

.loaderImage {
    height: 100%;
    position: absolute;
    width: 100%;
    background: #000000;
    opacity: .2;
    z-index: 100;
    display: none;
    i {
        position: absolute;
        top: 50%;
        left: 48%;
        transform: translate(-50%, -50%);
        color: white;
    }
}
