.testimonials-section {
    background-color: $dark-section-background;
    .testimonial-slider {
        @include breakpoint(768) {
            height: 200px;
        }

        .swiper-wrapper {
            align-items: center;
            .swiper-slide {
                background-color: $dark-section-background;
                color: white;
                .slide-content {
                    max-width: 800px;
                    text-align: left;
                    .text {
                        line-height: 1.2;
                        font-size: 17px;
                        @include breakpoint(992) {
                            line-height: 1.4;
                            font-size: 22px;
                        }
                    }
                }
            }
        }
    }
}
