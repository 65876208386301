.esspo-articles {
    .article-v1 {
        &.news-page{
            margin-bottom: 15px;
            height: 410px;
            display: flex; 
            flex-direction: column;
        .article-img {
            flex: 1 1 auto;
        }
        .article-heading {
            margin-bottom: 20px;
            margin-top:5px;
            height: 60px;
            overflow: hidden;
            text-overflow: ellipsis;
            h3, h4{
                margin: 0 !important;
                padding-top:5px;
                line-height: 25px;
            }
        }
        .article-text{
            font-size: 14px;
            height: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 20px;
            p{
                margin: 0 !important;
                padding-top:2px;
            }
        }
        }
          
        h2 {
            margin-top: 0;
        }

        .heading-section {
            text-align:left;
            


            .article-heading {
                 margin-bottom: 20px;
                 height: 60px;
                 overflow:hidden;
                 text-overflow: ellipsis;
                a {
                    text-transform: uppercase;
                }
            }

            .feed-separator {
                margin: 10px 0;
            }
            h4{
                line-height: 25px;
            }
        }

        .article-date {
            margin-top: 10px;
            font-weight: 100;
            color: #333333;
            font-size: 12px;
        }

        .article-img {
            height: 160px;
            width: 100%;
            object-fit: cover;
        }

        a {
            color: #333333;
            &:active, &:hover, &:visited, &:focus {
                color: #333333;
                text-decoration: none;
            }
        }

        .event-image {
            width: 100%;
            height: 300px;
            background-repeat: no-repeat;
            object-fit: cover;
        }

        .tile {
            box-shadow: 0 4px 4px #00000011;
            position: relative;
            .white-section {
                background: white;
                padding: 12px 15px 15px;
                height: 165px;
                overflow: hidden;
            }

            .event-details {
                background: #3a3a3ae3;
                border-bottom: 1px solid gainsboro;
                height: 67px;
                padding: 15px;
                position: absolute;
                margin-top: -67px;
                width: 100%;
                color: white;

            }
        }

        &.thin {
            margin-bottom: 0;
        }
    }

    &.useful {
        padding-bottom: 30px;

        line-height: 24px;
        font-size: 17px;
        p {
            font-size: 17px;
            line-height: 24px;
        }

        .learn-more {
            padding-top: 15px;
        }
    }
}

