.public-content-wrapper {
    margin-bottom: 50px;

    .element {
        //margin-bottom: 20px;
        //padding: 0 20px 0;

        h1,h2,h3,h4 {
            margin-top: 0;
        }
        p {
            margin: 0;
        }
        .tags {
            margin-top: 12px;
            .tag {
                font-size: 12px;
                padding: .3em .6em .3em;
            }

            .tags-label {
                margin-bottom: 5px;
            }
        }

        .balloons {
            margin-top: 25px;
        }

        .date {
            margin-top: 20px;
        }

        &.offset-top {
            padding-top: 30px;
        }

        &.single-project {
            font-size: 17px;
            line-height: 24px;
        }

        &.single {
            background: white;
            padding: 20px 20px 28px;

            h1 {
                font-size: 20px;
                font-weight: bold;
            }

            h2 {
                font-size: 18px;
                font-weight: bold;
            }

            h3, h4, h5 {
                font-size: 16px;
                font-weight: bold;
            }

            .date {
                margin-top: 0;
                i {
                    margin-left: 1px;
                }
                span {
                    margin-left: 6px;
                }
            }

            .job-description {
                margin-top: 7px;
                font-size: 20px;
            }

            .description {
                margin-top: 5px;

                &:before {
                    content: '';
                    margin-bottom: 9px;
                    height: 1px;
                    width: 30px;
                    display: block;
                    background: grey;
                }
            }

            .additional {
                span {
                    margin-left: 5px;
                }
            }

            .jobtiger {
                a {
                    margin-left: 5px;
                }
            }

        }

        .jobtiger {
            margin-top: 10px;
        }

        .activities, .description {
            margin-top: 10px;
        }
    }

    .internships-element {
        height: 230px;
        background: white;
        margin-bottom: 20px;
        padding: 20px 20px 28px;

        h1,h2,h3,h4 {
            margin-top: 0;
        }
        p {
            margin: 0;
        }

        .titles {
            height: 98px;
            margin-top: 10px;
            overflow: hidden;
            .title {
                font-weight: 600;
            }
        }

        .additional {
            height: 16px;
            span {
                margin-left: 5px;
            }
        }

        .date {
            i {
                margin-left: 1px;
            }
            span {
                margin-left: 6px;
            }

            &:before {
                content: '';
                margin-bottom: 9px;
                height: 1px;
                width: 30px;
                display: block;
                background: grey;
            }
            height: 25px;
            margin-top: 13px;
        }

        box-shadow: $shadow;
        transition: box-shadow 200ms;

        &:hover {
            box-shadow: $shadow-hover;
        }
    }

    .product-description {
        img {
            float: left;
            padding: 15px;
            padding-left: 0px;
        }
    }

    hr {
        border-top: 1px solid $hr-color;
    }

    .short-description {
        margin-top: 12px;
    }
}

.balloon-label {
    font-size: 18px;
    padding: 5px 10px 5px;
    margin-right: 10px;
    color: $main-text;
    a {
        color: white;
        &:active, &:hover, &:visited, &:focus{
            color: $main-text;
            text-decoration: none;
        }
    }

    &.link {
        &:hover {
            background: #CCCCCC;
        }
    }

}