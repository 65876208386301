.company-public-wrapper {
    .company-label {
        font-size: 18px;
        font-weight: 600;
        margin-top: 20px;

        &:first-of-type {
            margin: 0;
        }
    }

    img {
        margin: 10px;
    }

    .company-logo {
        margin-top: 20px;
        margin-left: 0;
    }
}

.company-container {
    margin-top: 20px;
}

.project-status {
    margin: 10px 0 10px;
}