.default-blue-gradient {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4263d6+0,7ec2b0+100 */
    background: #4263d6; /* Old browsers */
    background: -moz-linear-gradient(left, #4263d6 0%, #7ec2b0 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #4263d6 0%, #7ec2b0 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #4263d6 0%, #7ec2b0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4263d6', endColorstr='#7ec2b0',GradientType=1 ); /* IE6-9 */
}


.companies-gradient {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4263d6+0,7ec2b0+100 */
    background: #5baf3f; /* Old browsers */
    background: -moz-linear-gradient(left, #5baf3f 0%, #16b1aa 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #5baf3f 0%, #16b1aa 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #5baf3f 0%, #16b1aa 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4263d6', endColorstr='#7ec2b0',GradientType=1 ); /* IE6-9 */
}

.experts-gradient {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4263d6+0,7ec2b0+100 */
    background: #5baf3f; /* Old browsers */
    background: -moz-linear-gradient(left, #fdc609 0%, #16bccc 50%, #159a63 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #fdc609 0%, #16bccc 50%, #159a63 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #fdc609 0%, #16bccc 50%, #159a63 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4263d6', endColorstr='#7ec2b0',GradientType=1 ); /* IE6-9 */
}

.button-standard-gradient {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4263d6+0,7ec2b0+100 */
    background: #579fff; /* Old browsers */
    //background: -moz-linear-gradient(left, #57baCD 0%, #7ec2b0 100%); /* FF3.6-15 */
    //background: -webkit-linear-gradient(left, #57baCD 0%,#7ec2b0 100%); /* Chrome10-25,Safari5.1-6 */
    //background: linear-gradient(to right, #57baCD 0%,#7ec2b0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4263d6', endColorstr='#7ec2b0',GradientType=1 ); /* IE6-9 */
}

.triangle {
    &:hover, &:focus, &.active {
        position: relative;

        &:before {
            position: absolute;
            top: 0;
            left: -35px;
            content: " ";
            width: 35px;
            height: 35px;

            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,ffffff+51,ffffff+100&0+0,0+50,1+51,1+100 */
            background: -moz-linear-gradient(-45deg, rgba(30,87,153,0) 0%, rgba(251,252,253,0) 50%, rgb(241, 241, 241) 51%, rgb(241, 241, 241) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(-45deg, rgba(30,87,153,0) 0%,rgba(251,252,253,0) 50%, rgb(241, 241, 241) 51%, rgb(241, 241, 241) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(135deg, rgba(30,87,153,0) 0%,rgba(251,252,253,0) 50%, rgb(241, 241, 241) 51%, rgb(241, 241, 241) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }
    }
}