.intro-section {
    background-color: #FFFFFF;
    position: relative;
    @include breakpoint(768) {
        height: 200px;
    }

    .intro-content {
        color: #333333;
        max-width: 800px;
        text-align: center;
        margin: 20px auto;
        @include breakpoint(768) {
            margin: 0 auto;
            top: 50%;
            transform: translateY(50%);
        }
        .heading {
            margin-top: 0;
        }
        .text {
            font-size: 17px;
            @include breakpoint(992) {
                line-height: 1.4;
                font-size: 22px;
            }
        }
    }
}