.single-event {
    .video{
        margin: 20px auto;
        iframe{
            width:100% !important;
        }
    }
    .events-text {
        margin: 20px 0;
    }
}