// bootstrap and font-awesome fonts public dirs
$fa-font-path:   "fonts/font-awesome";
$icon-font-path: "fonts/bootstrap/";

@import url('https://fonts.googleapis.com/css?family=Roboto');

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//FontAwesome
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands";

// select 2
@import "node_modules/select2/dist/css/select2";
@import "select2-bootstrap";

// jquery UI
@import "node_modules/jquery-ui-bundle/jquery-ui";

//bootstrap-datepicker css
@import "node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker";

//swiper css
@import "node_modules/swiper/dist/css/swiper";

//reset some default classes
@import "reset";

// Helpers
@import "helpers/variables";
@import "helpers/mixins";
@import "helpers/colors";

//Common
@import "common/global";

//components
@import "components/svg-icons";
@import "components/swiper";
@import "components/gradients";
@import "components/buttons";
@import "components/others";
@import "components/links";
@import "components/spinner";
@import "components/tolltips";

// Main classes
@import "main";

//layout partials
@import "layout/navigation-row-one";
@import "layout/navigation-row-two";
@import "layout/footer";

//pages private
@import "homepage/main-slider";
@import "homepage/news-section";
@import "profile/profile";
@import "profile/navigation";
@import "auth/register";
@import "auth/login";

//pages public
@import "public/company/company";
@import "public/partials/tiles";
@import "public/partials/tree-menu";
@import "public/partials/articles";
@import "public/partials/single-article";
@import "public/partials/public-content";
@import "public/static/static-page";
@import "public/events/single-event";
@import "public/expert/expert";
@import "public/main-section";
@import "public/search/results";

//partials
@import "partials/members";
@import "partials/feed";
@import "partials/breadcrumbs";
@import "partials/side-menu";
@import "partials/input-forms";
@import "partials/image-from";
@import "partials/sorting-module";
@import "partials/blocks-section";
@import "partials/testimonials-section";
@import "partials/intro-section";


