.feed-container{
    margin-top: 0;
    background-color: #DCDCDC;
     &.members{
        margin-top: 45px;
    }
    &.projects{
        background-color: #FFF;
    }
    .feed-section{
        margin-top: 0px;

        &.new-projects-section {
            background-color: #FFF;
        }
            h2{
                text-transform: uppercase;
                font-size: 26px;
                font-weight: 700;
            }
        &.home-feed {
            margin-top: 0px;
            
            h2{
                text-transform: uppercase;
                font-size: 26px;
                font-weight: 700;
            }
        }

        .feed {
            background: #DCDCDC;
            padding: 25px 45px;
            color: $main-text;

            hr {
                border-color: $hr-color;
                &.top-hr {
                    margin-bottom: 0;
                    border-top: 2px solid #4d4e4c;
                }
            }


            .feed-category {
                hr {
                    margin: -1px 0 0 0;
                }

                h3 {
                    border-bottom: 2px solid $main-text;
                    display: inline-block;
                    margin-bottom: 0;
                    padding: 0 0 10px 0;
                    font-size: 25px;
                }
            }

            .feed-wrapper {
                margin-top: 20px;
                margin-bottom: 20px;

                a {
                    color: $main-text;
                    &:active, &:hover, &:visited, &:focus {
                        text-decoration: none;
                    }
                }

                .feed-heading {
                    h4 {
                        font-weight: 300;
                        margin: 0;
                        text-transform: uppercase;
                        padding-bottom: 10px;
                    }
                }

                .feed-desc {
                    font-size: 14px;
                }
            }

            .feed-view-more {
                text-align: center;
            }
        }
        .feed-separator{
            background-color:#4d4e4c;
        }

        .swiper-slide {
            background-color: transparent;
        }

        .article-v1 {
            height: 410px;
            overflow: hidden;
            background: transparent;
            .inner-slider-container{
                border: 1px solid #d1d1d1;
                box-shadow: 3px 3px 5px #d1d1d1;
                padding:10px;
                height:95%;
                width:98%;
                background:#FFF;
            }
            
            .fa {
                font-size: 160px;
            }
            .article-img{
                &.feeds{
                        height: 150px;
                        /*border: 10px solid rgba(189, 195, 199, 0.6);*/
                        margin: 0 auto;
                        div{
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center center;
                            width:100%;
                            height:100%;
                        }

                        img{
                            width: 100%;
                            height: 100%;
                            flex: 1 1 auto;
                        }
                        &.no-img {
                            font-size: 106px;
                            padding-top:4px;
                            &.internee-img{
                                padding-top:12px;
                            }
                        }
                    &.member-img{
                        border-radius: 50%;
                        height: 150px;
                        width: 150px;
                        border: 10px solid rgba(189, 195, 199, 0.6);
                        margin: 0 auto;
                        div{
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center center;
                            width:100%;
                            height:100%;
                            border-radius: 50%;
                        }

                        img{
                            width: 100%;
                            height: 100%;
                            border-radius: 50%; 
                            flex: 1 1 auto;
                        }
                        &.no-img {
                            font-size: 106px;
                            padding-top:4px;
                        }
                    }
                        
                }
            }

            .heading-section {
                padding: 0px;
                &.member{
                    text-align: center;
                    .article-date{
                        text-align: left;
                    }
                }
                .article-text{
                    p{
                        margin: 0 !important;
                    }
                }
            }
        }

        .swiper-pagination {
            position: static;
            padding: 20px;
        }
    }
}

