.expert-section {
    .expert-header {
        background: #1390ae;
        height: 115px;
        position: relative;

        img {
            position: absolute;
            left: 50px;
            bottom: -65px;
            border: 4px solid #ffffff;
            box-shadow: 3px 4px 2px #00000022;
            background: white;
        }

        .name {
            position: absolute;
            bottom: 5px;
            color: white;
            left: 200px;
        }

        .actions {
            position: absolute;
            right: 15px;
            top: 15px;
        }
    }

    .expert-details {
        background: white;
        padding: 80px 55px 25px;

        .main-info {
            display:flex;
            justify-content: space-between;

            .social-icon-svg {
                margin-right: 5px;
            }
        }
    }

    .available-projects {
        background: white;
        margin-top: 20px;
        padding: 20px 55px;
        .heading {
            padding: 8px;
        }

        table {
            margin: 0;
        }
    }

}
