.navigation1 {
    background: white;
    @include breakpoint(1200) {
        background: #303a44;
    }
    color: $outer-color;
    border: none;
    border-radius: 0;
    width: 100%;
    z-index: 200;

    @include breakpoint(1440) {
        // width: 1440px;
    }

    .navbar-nav {
        margin: 0 -15px;
        a {
            color: $main-text !important;
            @include breakpoint(1200) {
                color: #FFF !important;
            }
            &:hover, &:active, &:visited, &:focus {
                color: #01FFDC;
                background-color: inherit;
            }
        }
        .dropdown {
            .dropdown-menu {
                li {
                    a {
                        color: #333333 !important;
                    }
                }
            }
        }
    }

    .navbar-brand {
        max-width: 150px;
        padding: 5px;
        margin-left: 10px;
        img {
            width:73%;
            height:auto;
        }
    }

    .navbar-right {
        li {
            a {
                padding: 18px 15px;
            }

            &.search {
                a {

                }
            }
        }
    }

    .navbar-toggle {
        &.collapsed {
            background: none;
        }
        span {
            background: #FFFFFF !important;
        }
    }
    .social-links {
        padding: 0;
        li {
            &:nth-of-type(1){
                padding-left: 15px;
            }
            a {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 700;
                color: $main-text;
                @include breakpoint(1200) {
                    color: #FFF;
                }
                &:active, &:focus, &:visited, &:hover {
                    color: $main-text;
                    text-decoration: none;
                }
                padding: 19px 5px 11px;
            }
        }

        .social-icon-svg {
            fill: $main-text;
            @include breakpoint(1200) {
                fill: #FFF;
            }
        }
    }
    .dropdown {
        &.open {
            a {
                background: initial !important;
            }
        }
    }
    .search {
        width: 100%;
        display: block;
        height: 45px;
        @include breakpoint(1200) {
            width: inherit;
            height: inherit;
        }
        .search-input {
            float: left;
            width: 260px;
            padding: 12px 15px;
            input {
                height:30px;
                background: transparent;
                border-color: $main-text;
                color: $main-text;
                @include breakpoint(1200) {
                    border-color: #FFF;
                    color: #FFF;
                }
            }

            span {
                button {
                    background: transparent;
                    border-color: $main-text;
                    height:30px;
                    padding: 6px 12px 4px !important;
                    @include breakpoint(1200) {
                        border-color: #FFF;
                    }
                }
            }

            &:focus {
                outline: none;
            }
        }

        .search-icon {
            float: left;
            padding: 15px;
            color: $outer-color;
            @include breakpoint(1200) {
                color: #FFF;
            }
            
        }
        
        .search-icon-svg {
            @include breakpoint(1200) {
                fill: #FFF;
            }   
        }
    }

    .site-navigation {
        list-style-type: none;
        width: 100%;
        padding: 0;
        li {
            a {
                color: $main-text;
                @include breakpoint(1200) {
                    color: #FFF;
                }
                text-transform: uppercase;
                &:hover, &:active {
                    color: #333333;
                }
            }
        }
    }
}

#affix1 {
    position: fixed;
    top: 0;
    @include breakpoint(1200) {
        position: static;
    }
}
